import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import styles from './Logo.scss'
import LDAWPLogo from '../../images/ldawp_logo.png'
import CleaResultLogo from '../../images/clearesult_logo.svg'
import PECOLogo from '../../images/peco_logo.svg'
import CleanTechLogo from '../../images/cleantech_logo.png'
import NWELogo from '../../images/nwe_logo.svg'
import DTELogo from '../../images/dte_logo.svg'
import EDCLogo from '../../images/edc_logo.jpg'

const mapLogoToIcon = logo => {
  switch (logo) {
    case 'Clearesult':
      return CleaResultLogo
    case 'LADWP':
      return LDAWPLogo
    case 'PECO':
      return PECOLogo
    case 'CleanTech':
      return CleanTechLogo
    case 'NWE':
      return NWELogo
    case 'DTE':
      return DTELogo
    case 'EDC':
      return EDCLogo
  }
}
export class Logo extends React.Component {
  static propTypes = {
    maxHeight: PropTypes.string.isRequired,
    theme: PropTypes.object
  }
  render() {
    const { theme = {} } = this.props
    const iconName = mapLogoToIcon(theme.name)
    return iconName ? (
      <img src={iconName} width='100px' className={styles['logo-img']} />
    ) : (
      <svg
        className={classNames(styles['logo-svg'], styles[this.props.maxHeight])}
        xmlns='http://www.w3.org/2000/svg'
        width='363'
        height='81'
        viewBox='0 0 363 81'
      >
        <path
          className={styles['green']}
          d='M322.541 53.137c.472 1.201 2.19.424 3.228.646 11.574-.031 23.16.062 34.726-.047 1.033-2.002.3-4.751.238-7.017-1.22-11.075-8.941-21.261-19.565-24.88-11.46-4.142-25.31-1.163-33.566 7.945-9.007 9.432-10.433 24.865-3.715 35.96 5.703 9.678 17.133 14.933 28.16 14.634 9.284.068 18.714-3.747 24.61-11.064.358-.662 1.996-1.687.999-2.474-3.131-1.682-6.21-3.498-9.374-5.099-1.667.662-2.503 2.884-4.232 3.66-5.82 4.029-13.647 4.382-20.24 2.25-10.382-3.407-15.523-17.01-10.07-26.452 4.136-7.65 13.774-11.744 22.128-9.206 5.825 1.574 10.55 6.355 12.487 11.99-.283 1.078-1.753.395-2.535.586-7.625.034-15.269-.067-22.883.05-.831.566-.199 1.826-.396 2.66z'
        />
        <path
          className={styles['green']}
          d='M240.475 53.777a365.16 365.16 0 0 0-5.58.034c-.98.456-.116 1.779-.147 2.54 1.66 9.05 7.978 16.983 16.328 20.795 4.633 2.19 9.774 3.239 14.889 3.235 8.652.124 17.53-2.925 23.543-9.305.82-1.276 2.8-2.3 2.877-3.902-.585-.737-1.65-.963-2.414-1.504-2.424-1.292-4.795-2.718-7.25-3.93-1.06.172-1.43 1.578-2.325 2.098-4.855 4.69-12.116 5.735-18.56 4.738-6.464-.906-12.201-5.483-14.46-11.613-.674-.905-.282-2.58-1.32-3.162-1.858-.012-3.72-.022-5.581-.024z'
        />
        <path d='M264.947 20.191a31.497 31.497 0 0 0-8.416 1.082c-10.11 2.575-18.57 10.804-21.174 20.942.013.646-.794 1.588-.154 2.111 2.933.13 5.908.017 8.856.055.826-.125 1.805.239 2.552-.156 2.738-8.225 11.482-13.999 20.14-13.067 6.706.441 12.737 4.928 15.513 10.98.34.699 1.303 2.12.134 2.58-7.653.086-15.322.014-22.98.038-.693.155-1.655-.317-2.195.238-.136 2.235-.017 4.515-.057 6.766.138.563-.369 1.69.461 1.838 11.756-.002 23.516-.002 35.273-.002.692-.147 1.62.295 2.182-.217.804-8.288-1.939-16.931-7.459-23.197-5.623-6.523-14.151-9.94-22.676-9.99z' />
        <path d='M198.347 31.076c10.36 0 19.113 8.697 19.112 18.992 0 10.297-8.752 18.996-19.111 18.996-10.36 0-19.112-8.699-19.112-18.996 0-10.295 8.753-18.992 19.112-18.992zm19.54-30.89a.46.46 0 0 0-.43.459v24.808a.844.844 0 0 1-.45.738.827.827 0 0 1-.86-.058l-1.018-.715c-4.84-3.409-10.641-5.213-16.781-5.213-8.208 0-15.878 3.084-21.592 8.68-5.716 5.6-8.863 13.122-8.863 21.183 0 16.533 13.662 29.985 30.455 29.985 5.752 0 12.191-1.958 16.8-5.108l1.008-.687a.821.821 0 0 1 .858-.047.822.822 0 0 1 .443.732v3.338a.459.459 0 0 0 .461.46h11.02a.459.459 0 0 0 .458-.46V.645a.46.46 0 0 0-.458-.46h-11.02a.46.46 0 0 0-.031 0z' />
        <path d='M150.874 78.739a.458.458 0 0 1-.457-.458V.645c0-.253.205-.459.457-.459h11.142a.46.46 0 0 1 .459.459v77.636a.459.459 0 0 1-.459.458z' />
        <path d='M129.729 78.742c-.807-.134-.337-1.214-.461-1.761.014-18.618-.027-37.242.02-55.857.344-.65 1.28-.183 1.861-.324 3.298.024 6.606-.048 9.898.036.558.58.16 1.562.28 2.318-.014 18.42.027 36.846-.02 55.264-.344.649-1.278.182-1.858.323-3.24 0-6.482-.001-9.72.001z' />
        <path d='M129.729 11.212a.46.46 0 0 1-.461-.458V.645a.46.46 0 0 1 .461-.459h11.14c.253 0 .458.206.458.459v10.109a.459.459 0 0 1-.458.458z' />
        <path d='M90.369 79.337c-8.35 0-14.295-2.439-18.179-7.457-3.56-4.6-5.365-11.538-5.365-20.622V21.261a.46.46 0 0 1 .458-.46h11.02a.46.46 0 0 1 .46.46v26.186c0 5.656.311 11.43 2.566 15.337 2.239 3.879 6.096 5.685 12.139 5.685 9.142 0 14.821-5.908 14.821-15.421V21.262a.46.46 0 0 1 .458-.46h11.02a.46.46 0 0 1 .46.46v57.021a.46.46 0 0 1-.46.459h-11.02a.458.458 0 0 1-.458-.459v-4.351a.83.83 0 0 0-.834-.83.83.83 0 0 0-.539.199l-1.065.907c-3.874 3.308-9.373 5.129-15.482 5.129z' />
        <path d='M31.236 80.052c-6.474-.009-12.828-2.256-18.108-5.923-1.428-.116-.939 1.765-1.003 2.63-.048.691.38 2.266-.861 1.983-3.62-.057-7.285.112-10.877-.08-.54-.9-.016-2.18-.2-3.202C.211 50.464.138 25.454.224.466.836-.23 2.05.399 2.864.186h8.803c.945.863.24 2.634.458 3.886.047 7.233-.093 14.49.069 21.707.727 1.237 1.966-.242 2.715-.675 12.666-8.729 31.728-5.4 40.808 6.99 8.294 10.617 7.87 26.876-.988 37.036-5.718 6.884-14.546 10.979-23.493 10.922zm0-48.976c-10.273-.185-19.552 9.175-19.104 19.474.105 10.296 9.804 19.22 20.07 18.49 10.254-.355 19.092-10.131 18.09-20.407-.66-9.608-9.427-17.678-19.056-17.557z' />
      </svg>
    )
  }
}

export default Logo
